import { ImgLeft } from "../Components/specials/ImgLef";
import b1 from "../assets/img/community/SPW09737.jpg";
import {ReactComponent as DiscordLogo} from "../assets/svgs/discord.svg"
import {ReactComponent as MailLogo} from "../assets/svgs/mail.svg"
import LogoDarkBlue from "../assets/img/LogoEinfarbigDarkBlue.png"

export const Mitmachen = () => {
    return (
        <div className="Mitmachen ContentWrapper">
            <img className="Mitmachen-img" src={LogoDarkBlue} alt=""/>
            <div className="Mitmachen-Left">
            <ImgLeft img={b1} alt="bild" />
            </div>
            <div className="Mitmachen-Right">
                <div className="Mitmachen-text-titel">
                    WERDE TEIL UNSERER COMMUNITY
                </div>
                <div className="Mitmachen-text-content">
                    Du möchtest einem Team von uns beitreten, uns in der Orga unterstützen oder hast noch offene Fragen? Melde dich gerne via Discord oder Mail bei uns: 
                </div>
                <div className="Mitmachen-text-btn">
                <a href="https://discord.gg/5QP76kT" target="_blank" rel="noreferrer"><DiscordLogo/></a>
                        <a href="mailto: info@hhaie.de"><MailLogo/></a>
                  
                </div>
            </div>
        </div>
    );
}