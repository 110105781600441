import { ImgLeft } from "../Components/specials/ImgLef";
import b1 from "../assets/img/ueberuns/webp/headsetHai.webp";
import b2 from "../assets/img/ueberuns/webp/lanparty.webp";
import b3 from "../assets/img/ueberuns/group.jpg";
import b4 from "../assets/img/ueberuns/couchgames.jpg";
import b5 from "../assets/img/ueberuns/lanarea.jpg";
import { ImgRight } from "../Components/specials/ImgRight";

export const UberUns = () => {


    return (
        <div className='home uberuns ContentWrapper'>
            <div className='content-element content-element-rest'>
                <div className="content-element-left">
                    <h2>Über uns</h2>
                    <p>
                    Für uns ist E-Sport mehr als nur ein wöchentlicher Spielbetrieb. Daher legen wir bei den Haien viel Wert auf die eigene, regionale Community. Unser Ziel ist es, dass alles Spieler*innen sich bei uns willkommen fühlen und, dass aus Mitspieler*innen auch irgendwann Freunde werden. Hierfür organisieren wir um den Spielbetrieb viele Communityveranstaltungen, damit wir online, sowie offline unseren Mitglieder*innen ein entspanntes Umfeld mit einem schönen Miteinander ermöglichen. Einen großen Fokus hierbei legen wir auf den studentischen E-Sport. Hier stellen wir uns schon bei diversen Ersti-Veranstaltungen den angehenden Studierenden vor und bieten auf unserem Server einfachen Kontakt zu höheren Semestern, um anstehende Fragen zu beantworten. Mit einem Umfeld aus Menschen mit gleichen Interessen probieren wir somit den Erstsemestern direkt den Einstieg in ihren neuen Lebensabschnitt zu erleichtern.
                    </p>
                </div>
                <div className="content-element-right">
                    <ImgRight img={b5} alt="" />

                </div>
            </div>
            <div className='content-element content-element-rest'>
                <div className="content-element-left onlydesktop">
                    <ImgLeft img={b3} alt="" />

                </div>
                <div className="content-element-right">
                    <h2>Der Ursprung</h2>
                    <p>
                    Seinen Ursprung hat der Uni-Esport in Hamburg auf einer Lan-Party der TUHH in 2016. Hier lernte sich das erste Uni-Team um Christian | Chr1z kennen und beschloss schon bald die Teilnahme an der League of Legends Uniliga. In der ersten Saison schaffte das Team direkt eine kleine Sensation und gewann das gesamte Turnier. Später folgte dann noch der zweite Platz bei der Milan Games Week und ebenfalls der zweite Platz beim University College Cup in Porto. In dieser Zeit wurde dann von Simon | Tabbian und Christian | Chr1z die “LoL-AG” an der TUHH gegründet und ein zweites League of Legends Team entstand. Unter dem Banner von “TUHH GamING” wurde Hamburg ab dann in der League of Legends Uniliga vertreten.
                    </p>
                </div>
                <div className="content-element-left onlymobile">
                    <ImgLeft img={b3} alt="" />
                </div>
            </div>
            <div className='content-element content-element-rest'>
                <div className="content-element-left">
                    <h2>Die ersten Haie</h2>
                    <p>
                    Trotz des Namens "TUHH GamING" waren jedoch auch immer wieder Studierende anderer Hamburger Unis Teil des Teams. Daher entschlossen sich Philipp “Welli” Welteke und Markus “Sav” Mutas im Juni 2019 dazu, die Hamburger Haie zu gründen. Plan hierbei war es offiziell alle Hamburger Unis in der Uniliga zu vertreten und auch das Spieleportfolio, über League of Legends hinaus, zu erweitern.Der Plan ging auf und bereits in der ersten Saison zählte die Organisation ca. 50 neue Mitglieder und trat mit sechs Teams in vier verschiedenen Spielen in der Uniliga an. Anfang 2020 kam dann noch die AG an der Uni Hamburg hinzu, um dort die Studierenden unserer Organisation noch besser vertreten zu können.
                    </p>
                </div>
                <div className="content-element-right">
                    <ImgRight img={b1} alt="" />

                </div>
            </div>
            <div className='content-element content-element-rest'>
                <div className="content-element-left onlydesktop">
                    <ImgLeft img={b2} alt="" />

                </div>
                <div className="content-element-right">
                    <h2>Großer Zuwachs</h2>
                    <p>
                    Mit den Anfängen von Corona im März/April 2020 saßen viel Studierende mit viel Zeit zu Hause. Mit unserer noch recht jungen Organisation boten wir hier eine Ablenkung von den Geschehnissen um uns herum. Wir organisierten Veranstaltungen für die neuen Erstsemester, welche ohne Präsenzveranstaltungen Schwierigkeiten hatten, Gleichgesinnte in der Uni zu finden. Es bildeten sich neue Lerngruppen und sogar Freundeskreise. Bedingt durch diesen starken Zuwachs in unserer Community wurden die Haie schnell zu einer bekannten Marke im deutschen Breiten-E-Sport. Es folgen erste Erfolge und auch unser Logo bekam einen neuen Anstrich.
                    </p>
                </div>
                <div className="content-element-left onlymobile">
                    <ImgLeft img={b2} alt="" />
                </div>
            </div>
            <div className='content-element content-element-rest'>
                <div className="content-element-left">
                    <h2>Vereinsgründung</h2>
                    <p>
                    Mitte 2021 folgte dann die logische Konsequenz: Wir gründen einen Verein. Gesagt, getan. Am wurde 11.06.2021 der Hamburger Haie e.V. gegründet und steht, ein paar bürokratische Hürden später, seit dem 02.09.2021 offiziell im Vereinsregister der Stadt Hamburg. Es folgten neue Partnerschaften und Sponsoring, sowie unsere erste eigene Merchandise-Kollektion.
                    </p>
                </div>
                <div className="content-element-right">
                    <ImgRight img={b4} alt="" />

                </div>
            </div>
            <div className='content-element content-element-rest'>
                <button className="mediakit"><a href="https://cloud.hhaie.de/s/qRWxP7rQNgKbtWt" target="_blank" rel="noreferrer">Mediakit</a></button>
            </div>
        </div>
    );
}