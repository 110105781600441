export const Impressum = () => {
    return (
      <div className="impressum ContentWrapper">
        <h1 className="impressum-title">Impressum</h1>
        <div className="impressum-content">
          <h2 className="impressum-content-title">
            Hamburger Haie e.V.
            <div className="impressum-content-text">z.H. Philipp Welteke</div>
            <div className="impressum-content-text">Marienstraße 85</div>
            <div className="impressum-content-text">21073 Hamburg</div>
          </h2>
          <h2 className="impressum-content-title">
            Vertreten durch:
            <div className="impressum-content-text">
              Erster Vorstandsvorsitzender: Philipp Welteke
            </div>
            <div className="impressum-content-text">
              Zweiter Vorstandsvorsitzender: Jeff Raffael Gower
            </div>
            <div className="impressum-content-text">Kassenwart: Niklas Gerwens</div>
          </h2>
          <h2 className="impressum-content-title">
            Kontakt:
            <div className="impressum-content-text">Telefon: +491774770568</div>
            <div className="impressum-content-text">
              E-Mail:{" "}
              <a
                href="mailto: kontakt@hhaie.de"
                className="impressum-content-text-mail"
              >
                kontakt@hhaie.de
              </a>
            </div>
          </h2>
          <h2 className="impressum-content-title">
            Registereintrag:
            <div className="impressum-content-text">
              Eintragung im Amtsgericht Hamburg
            </div>
            <div className="impressum-content-text">Vereinsregister: 24817</div>
            <div className="impressum-content-text">
              Rechtsform: eingetragener Verein (e.V.)
            </div>
          </h2>
          <h2 className="impressum-content-title">
            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
            <div className="impressum-content-text">z.H. Philipp Welteke</div>
            <div className="impressum-content-text">Marienstraße 85</div>
            <div className="impressum-content-text">21073 Hamburg</div>
          </h2>
        </div>
        <h1 className="impressum-title">Haftungsausschluss</h1>
        <div className="impressum-content">
          <h2 className="impressum-content-title">
            Haftung für Inhalte
            <div className="impressum-content-text">
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
              die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können
              wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir
              gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
              allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
              als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
              gespeicherte fremde Informationen zu überwachen oder nach Umständen
              zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
              Informationen nach den allgemeinen Gesetzen bleiben hiervon
              unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
              Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
              Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
              Inhalte umgehend entfernen.
            </div>
          </h2>
          <h2 className="impressum-content-title">
            Haftung für Links
            <div className="impressum-content-text">
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf
              derenInhalte wir keinen Einfluss haben. Deshalb können wir für diese
              fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
              verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
              der Seiten verantwortlich. Die verlinkten Seiten wurden zum
              Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
              Rechtswidrige Inhaltewaren zum Zeitpunkt der Verlinkung nicht
              erkennbar. Eine permanenteinhaltliche Kontrolle der verlinkten
              Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
              nicht zumutbar. Bei Bekanntwerdenvon Rechtsverletzungen werden wir
              derartige Links umgehend entfernen.
            </div>
          </h2>
          <h2 className="impressum-content-title">
            Urheberrecht
            <div className="impressum-content-text">
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
              Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
              gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.
            </div>
          </h2>
          <h2 className="impressum-content-title">
            Datenschutz
            <div className="impressum-content-text">
              Die Nutzung unserer Webseite ist in der Regel ohne Angabe
              personenbezogener Daten möglich. Soweit auf unseren Seiten
              personenbezogene Daten (beispielsweise Name, Anschrift oder
              eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
              auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
              Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin,
              dass die Datenübertragung im Internet (z.B. bei der Kommunikation
              per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz
              der Daten vor dem Zugriff durch Dritte ist nicht möglich. Der
              Nutzung von im Rahmen der Impressumspflicht veröffentlichten
              Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
              angeforderter Werbung und Informationsmaterialien wird hiermit
              ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
              ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
              von Werbeinformationen, etwa durch Spam-Mails, vor.
            </div>
          </h2>
        </div>
      </div>
    );
  };
  