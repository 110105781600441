
interface team {
    name: string;
    Liga: string;
    Division: number;
    spieler: string[];
}


export const DepartmentTeamCard = (team: team) => {
    return (
        <div className="TeamCard">
            <div className="TeamCard-name">{team.name}</div>
            <div className="TeamCard-Liga">
            {team.Liga}&nbsp;{team.Division}</div>
                <div className="TeamCard-Spieler">
                    {team.spieler.map(spieler => {
                        return (
                            <span>
                                {spieler}
                            </span>
                        )
                    })}
                </div>
            



        </div>
    );
}