import { useEffect, useState } from "react";
import { ImgDropShadow } from "./ImgDropShadow";


interface PersonInfo {
    name: string,
    discord: string
}

interface departmentInfo {
    abteilung: string,
    elo: string,
    liegen: string[],
    teamCount: Number,
    abteilungsleitung: PersonInfo[],
    img: any
}

export const DepartmentInfoCard = (departmentInfo: departmentInfo) => {

    const [ligaText, setLigaText] = useState("")

    useEffect(() => {
        let tempText = ""
        for (let i = 0; i < departmentInfo.liegen.length; i++) {
            tempText = tempText + departmentInfo.liegen[i] + ", "
        }
        setLigaText(tempText.slice(0, -2))
    }, [departmentInfo.liegen])
    return (
        <div className="DepartmentInfoCard">
            <div className="DepartmentInfoCard-Wrapper">
                <div className="DepartmentInfoCard-Logo">
                    <ImgDropShadow img={departmentInfo.img} alt="" invert={true} />
                </div>
                <div className="DepartmentInfoCard-infotable">
                    <table>
                        <tr>
                            <th>LIGEN</th>
                            <td>{ligaText}</td>
                        </tr>
                        <tr>
                            <th>TEAMANZAHL</th>
                            <td>{departmentInfo.teamCount}</td>
                        </tr>
                        <tr>
                            <th>ELOBEREICH</th>
                            <td>{departmentInfo.elo}</td>
                        </tr>
                        <tr>
                            <th>BEREICHSLEITUNG</th>
                            <td>{departmentInfo.abteilungsleitung.map(person => {
                                return (<div>
                                    {person.name}<br />
                                    <i>Discord:</i> {person.discord}
                                </div>);
                            })}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
} 