export const ImgDropShadow = (props: any) => {

    if(props.invert)
    {
        if(props.selected){
            return(
                <div className="img-dropshadow img-selected">
                    <img src={props.img} alt={props.alt} />
                </div>
                
            );
        }
        return(
            <div className="img-dropshadow img-invert">
                <img src={props.img} alt={props.alt} />
            </div>
            
        );
    }
    else{
    return(
        <div className="img-dropshadow">
            <img src={props.img} alt={props.alt} />
        </div>
        
    );
    }
}