import { Link, useLocation } from "react-router-dom";
import {ReactComponent as HHaieLogo} from "../assets/svgs/LogoVektor.svg"
import {ReactComponent as YouTubeLogo} from "../assets/svgs/youtube.svg"
import {ReactComponent as TwitchLogo} from "../assets/svgs/twitch.svg"
import {ReactComponent as TwitterLogo} from "../assets/svgs/twitter.svg"
import {ReactComponent as InstagramLogo} from "../assets/svgs/instagram.svg"
import {ReactComponent as DiscordLogo} from "../assets/svgs/discord.svg"
import {ReactComponent as SocialsLogo} from "../assets/svgs/socials.svg"
import { useEffect, useState } from "react";

export const Header = () => {

    const [openBurgerClass, setOpenBurgerClass] = useState("");
    const burgerMenuAction = () =>{
        if(openBurgerClass === ""){
            setOpenBurgerClass("open");
        }else{
            setOpenBurgerClass("");
        }
    }

    const [openSocialClass, setOpenSocialClass] = useState("");
    const socialMenuAction = () =>{
        if(openSocialClass === ""){
            setOpenSocialClass("open");
        }else{
            setOpenSocialClass("");
        }
    }

    const location = useLocation();
    const [activeMoin, setActiveMoin] = useState("");
    const [activeSpiele, setActiveSpiele] = useState("");
    const [activeUberUns, setActiveUberUns] = useState("");
    const [activeMitmachen, setActiveMitmachen] = useState("");
    useEffect(()=>{
        switch(location.pathname){
            case '/':
                setActiveMoin("activePage");
                setActiveSpiele("");
                setActiveUberUns("");
                setActiveMitmachen("");
            break;
            case '/ueber-uns':
                setActiveMoin("");
                setActiveSpiele("");
                setActiveUberUns("activePage");
                setActiveMitmachen("");
            break;
            case '/mitmachen':
                setActiveMoin("");
                setActiveSpiele("");
                setActiveUberUns("");
                setActiveMitmachen("activePage-Mitmachen");
            break;
            case '/spiele':
                setGamesActivePage()
            break;
            case '/spiele/league-of-legends':
                setGamesActivePage()
            break;
            case '/spiele/counterstrike':
                setGamesActivePage()
            break;
            case '/spiele/rocket-league':
                setGamesActivePage()
            break;
            case '/spiele/rainbow-six':
                setGamesActivePage()
            break;
            case '/spiele/valorant':
                setGamesActivePage()
            break;
            case '/spiele/overwatch':
                setGamesActivePage()
            break;
            case '/spiele/sim-racing':
                setGamesActivePage()
            break;
            default:
                setActiveMoin("");
                setActiveSpiele("");
                setActiveUberUns("");
                setActiveMitmachen("");
            break;
            
        }

        setOpenBurgerClass("");
        setOpenSocialClass("");

    },[location])

    const setGamesActivePage = () => {
        setActiveMoin("");
        setActiveSpiele("activePage");
        setActiveUberUns("");
        setActiveMitmachen("");
    }

    return (
        <nav className='header'>
            <div className="header-desktop">
                <span className="header-desktop-logo"><HHaieLogo/></span>
                <span className="header-desktop-links">
                    <button className={"header-desktop-links-btn "+ activeMoin}><Link to="/">Moin</Link></button>
                    <button className={"header-desktop-links-btn " + activeSpiele}><Link to="/spiele">Spiele</Link></button>
                    <button className={"header-desktop-links-btn " + activeUberUns}><Link to="/ueber-uns">Über uns</Link></button>
                    <button className={"header-desktop-links-btn header-desktop-links-btn-special "+ activeMitmachen}><Link to="/mitmachen">Mitmachen</Link></button>
                </span>
                    <span className="header-desktop-socials">
                    <button className="header-desktop-socials-btn"><a href="https://www.youtube.com/channel/UCNrpOpbVGfqUbT0OSsXT4mw" target="_blank" rel="noreferrer"><YouTubeLogo/></a></button>
                    <button className="header-desktop-socials-btn"><a href="https://www.twitch.tv/hamburgerhaie/" target="_blank" rel="noreferrer"><TwitchLogo/></a></button>
                    <button className="header-desktop-socials-btn"><a href="https://twitter.com/hamburgerhaie" target="_blank" rel="noreferrer"><TwitterLogo/></a></button>
                    <button className="header-desktop-socials-btn"><a href="https://www.instagram.com/hamburgerhaie/" target="_blank" rel="noreferrer"><InstagramLogo/></a></button>
                    <button className="header-desktop-socials-btn"><a href="https://discord.gg/5QP76kT" target="_blank" rel="noreferrer"><DiscordLogo/></a></button>
                </span>
            </div>
            <div className="header-mobile">
                    <span className="header-mobile-burgerMenu-btn">
                            <div className={"burgerMenu-btn "+openBurgerClass} onClick={burgerMenuAction}>
                                <div className="burgerMenu-btn__burger"></div>
                            </div>
                    </span>
                    <span className="header-mobile-callToActionBtn">
                        <button className={"header-mobile-callToActionBtn-btn " + activeMitmachen}><Link to="/mitmachen">Mitmachen</Link></button>
                    </span>
                    <span className="header-mobile-socialMenu-btn">
                        <SocialsLogo onClick={socialMenuAction}/>
                    </span>
                    <span className={"header-mobile-burgerMenu " + openBurgerClass}>
                        <span className="header-mobile-burgerMenu-links">
                            <button className={"header-mobile-burgerMenu-links-btn " + activeMoin}><Link to="/">Moin</Link></button>
                            <button className={"header-mobile-burgerMenu-links-btn " + activeSpiele}><Link to="/spiele">Spiele</Link></button>
                            <button className={"header-mobile-burgerMenu-links-btn " + activeUberUns}><Link to="/ueber-uns">Über uns</Link></button>
                        </span>
                    </span>
                    <span className={"header-mobile-socialMenu " + openSocialClass}>
                        <span className="header-mobile-socialMenu-links">
                            <button className="header-mobile-socialMenu-links-btn"><a href="https://www.youtube.com/channel/UCNrpOpbVGfqUbT0OSsXT4mw" target="_blank" rel="noreferrer"><YouTubeLogo/></a></button>
                            <button className="header-mobile-socialMenu-links-btn"><a href="https://www.twitch.tv/hamburgerhaie/" target="_blank" rel="noreferrer"><TwitchLogo/></a></button>
                            <button className="header-mobile-socialMenu-links-btn"><a href="https://twitter.com/hamburgerhaie" target="_blank" rel="noreferrer"><TwitterLogo/></a></button>
                            <button className="header-mobile-socialMenu-links-btn"><a href="https://www.instagram.com/hamburgerhaie/" target="_blank" rel="noreferrer"><InstagramLogo/></a></button>
                            <button className="header-mobile-socialMenu-links-btn"><a href="https://discord.gg/5QP76kT" target="_blank" rel="noreferrer"><DiscordLogo/></a></button>
                        </span>
                    </span>
            </div>
            
        </nav>
    );
}

