import { useEffect, useRef, useState } from "react";
import {ReactComponent as HHaieLogo} from "../../assets/svgs/LogoVektor.svg"
import b1 from "../../assets/img/Polaroid-imgs/1.png";
import b2 from "../../assets/img/Polaroid-imgs/2.png";
import b3 from "../../assets/img/Polaroid-imgs/3.png";
import b4 from "../../assets/img/Polaroid-imgs/4.png";
import b5 from "../../assets/img/Polaroid-imgs/5.png";
import b6 from "../../assets/img/Polaroid-imgs/6.png";
import b7 from "../../assets/img/Polaroid-imgs/7.png";
import b8 from "../../assets/img/Polaroid-imgs/8.png";
const colors = [b1, b2, b3, b4, b5, b6, b7, b8];
const delay = 5000;

export const Polaroid = () => {

    const [index, setIndex] = useState(0);
    const timeoutRef: any = useRef(null); //shouldnt be any

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === colors.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);




    return (
        <div className="polaroid">
            <div className="polaroid-wrapper">
            <div className="polaroid-img">
                <span className="polaroid-onlymobile">
                <HHaieLogo/>
                </span>
                <div className="slideshow">
                    
                    <div
                        className="slideshowSlider"
                        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                    >
                        {colors.map((b, index) => (
                            <div
                                className="slide"
                                key={index}
                            >
                                <img className="polaroid-slider-img" src={b} alt={""} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="polaroid-text">
                <h1>Hamburger Haie</h1>
            </div>
            </div>
        </div>
    );
}
