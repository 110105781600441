import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Footer} from './Components/Footer';
import {Header} from './Components/Header';
import ScrollToTop from './Components/specials/ScrollToTop';
import {Home} from './Pages/Home';
import {UberUns} from './Pages/UberUns';
import {Departments} from './Pages/Departments';
import {Mitmachen} from './Pages/Mitmachen';
import {Impressum} from './Pages/Impressum';
import {Game} from "./types/game";
import {
    ABOUT_US_ROUTE, CALL_TO_ACTION_ROUTE,
    GAMES_CS_ROUTE, GAMES_LOL_ROUTE, GAMES_OW_ROUTE,
    GAMES_R6_ROUTE, GAMES_RL_ROUTE, GAMES_SIM_ROUTE,
    GAMES_ROUTE, GAMES_VALO_ROUTE, HOME_ROUTE, IMPRESSUM_ROUTE
} from "./types/routes";


function App() {
  return (
    <Router>
        <Header />
        <Routes>
          <Route path={HOME_ROUTE} element={<Home />} />
          <Route path={GAMES_ROUTE} element={<Departments gameName={Game.LOL}/>} />
          <Route path={GAMES_LOL_ROUTE} element={<Departments gameName={Game.LOL}/>} />
          <Route path={GAMES_RL_ROUTE} element={<Departments gameName={Game.RL}/>} />
          <Route path={GAMES_OW_ROUTE} element={<Departments gameName={Game.OW}/>} />
          <Route path={GAMES_R6_ROUTE} element={<Departments gameName={Game.R6}/>} />
          <Route path={GAMES_CS_ROUTE} element={<Departments gameName={Game.CS}/>} />
          <Route path={GAMES_VALO_ROUTE} element={<Departments gameName={Game.VALO}/>} />
          <Route path={GAMES_SIM_ROUTE} element={<Departments gameName={Game.SIM}/>} />
          <Route path={ABOUT_US_ROUTE} element={<UberUns />} />
          <Route path={CALL_TO_ACTION_ROUTE} element={<Mitmachen />} />
          <Route path={IMPRESSUM_ROUTE} element={<Impressum />} />
        </Routes>
      <Footer />
      <ScrollToTop />
    </Router>
  );
}

export default App;
