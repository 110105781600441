// GENERAL
export const HOME_ROUTE: string = '/';
export const GAMES_ROUTE: string = '/spiele';
export const ABOUT_US_ROUTE: string = '/ueber-uns';
export const CALL_TO_ACTION_ROUTE: string = '/mitmachen';
export const IMPRESSUM_ROUTE: string = '/Impressum-Datenschutz';

// GAMES
export const GAMES_RL_ROUTE: string = GAMES_ROUTE + '/rocket-league';
export const GAMES_LOL_ROUTE: string = GAMES_ROUTE + '/league-of-legends';
export const GAMES_OW_ROUTE: string = GAMES_ROUTE + '/overwatch';
export const GAMES_R6_ROUTE: string = GAMES_ROUTE + '/rainbow-six';
export const GAMES_CS_ROUTE: string = GAMES_ROUTE + '/counterstrike';
export const GAMES_VALO_ROUTE: string = GAMES_ROUTE + '/valorant';
export const GAMES_SIM_ROUTE: string = GAMES_ROUTE + '/sim-racing';
